<template>
	<div>
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%;">
					<a-row type="flex" justify="space-between">
						<a-col span="12">
							<a-button-group>
								<a-button type="primary" v-has="'study:save'" @click="$refs.addForm._show()">添加学习</a-button>
								<a-button type="primary" v-has="'study:category'"
									@click="$refs.category._show()">类别设置</a-button>
							</a-button-group>
						</a-col>
						<a-col span="12">
							<a-row type="flex" align="middle" justify="end" :gutter="3">
								<a-col span="12">
									<a-input v-model="param.keyword" allowClear search @pressEnter="getList()"
										@change="change" @on-clear="getList()" placeholder="关键词查询"></a-input>
								</a-col>
								<a-col span="6" v-has="'study:category'">
									<a-select v-model="param.categoryId" style="width:100%" @focus="
																				e => {
																					this.getCategoryList();
																				}
																			" @change="
																					e => {
																						this.getList();
																					}
																				" placeholder="根据类别进行筛选">
										<a-select-option value="">所有</a-select-option>
										<a-select-option v-for="category in categoryList" :key="category.id"
											:value="category.id">{{ category.name }}</a-select-option>
									</a-select>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>

		<a-table :columns="columns" :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false"
			:indentSize="25" :style="{ background: '#FFF' }" size="small" row-key="id" border :loading="loading"
			:data-source="data.records">
			<!-- 标题 -->
			<template slot-scope="row" slot="rowtitle">
				<a @click="$refs.detail.show(row.id)">{{ row.title }}</a>
			</template>
			<!-- 是否发布 -->
			<template slot-scope="row" slot="issue">
				<a-tooltip :title="row.issue ? '是' : '否'">
					<template v-if="row.issue == true">
						<a-icon type="check-circle" style="color:#090" />
					</template>
					<template v-else>
						<a-icon type="stop" />
					</template>
				</a-tooltip>
			</template>
			<!--操作-->
			<template slot-scope="row" slot="action">
				<a-button-group shape="circle" size="small">
					<a-tooltip title="修改数据"><a-button v-has="'study:save'" type="link" icon="edit"
							@click="$refs.addForm._show(row.id)"></a-button></a-tooltip>
					<a-tooltip title="删除数据"><a-button v-has="'study:del'" type="link" icon="delete"
							@click="del(row.id)"></a-button></a-tooltip>
				</a-button-group>
			</template>
		</a-table>
		<a-row>
			<a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
				<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext"
					@showSizeChange="setLimit" :current="data.current" />
			</a-col>
		</a-row>
		<add-form ref="addForm" @success="doRefresh"></add-form>

		<detail ref="detail"></detail>
		<Category ref="category"></Category>
	</div>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import utils from '@/common/utils';
import addForm from './components/addForm.vue';

import Category from './components/category.vue';
import detail from './components/detail.vue';

export default {
	components: { addForm, Category, detail },
	data() {
		return {
			data: {},
			loading: false,
			organList: apiUtil.getOrganList(),
			pageSize: 10,
			categoryList: [],
			total: '',
			param: {
				current: 1,
				limit: 20,
				keyword: '',
				categoryId: ''
			},
			columns: [
				{ title: '标题', scopedSlots: { customRender: 'rowtitle' }, width: 100 },
				{ title: '所属类别', dataIndex: 'categoryName', width: 160, resizable: true, align: 'center' },
				{ title: '评论', dataIndex: 'comments', width: 160, resizable: true, align: 'center' },
				{ title: '点赞', dataIndex: 'likes', width: 160, resizable: true, align: 'center' },
				{ title: '收藏', dataIndex: 'favorites', width: 160, resizable: true, align: 'center' },
				{ title: '是否发布', scopedSlots: { customRender: 'issue' }, width: 100, resizable: true, align: 'center' },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 100 }
			]
		};
	},
	mounted() {
		this.getList();
	},
	methods: {
		...apiUtil,
		// 获取数据
		getList() {
			this.loading = true;
			this.http
				.get('/platform/study/list', this.param)
				.then(ret => {
					this.data = ret.data;
					this.total = data.total;
					this.param.current = data.current;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		change(e) {
			if (e.target.value == '') {
				this.getList();
			}
		},
		// 删除
		del(id) {
			this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
				utils.showSpin();
				this.http.delete('/platform/study/del/' + id).then(ret => {
					if (ret.code == 200) {
						this.utils.success('操作成功！').then(() => {
							this.getList();
							// console.log(1111);
						});
					}
				});
			});
		},
		// 筛选条件变化重新获取数据
		doRefresh() {
			this.param.current = 1;
			this.getList();
		},

		// 筛选单位
		filterOrgan(e) {
			this.param.organId = e;
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getList();
		},
		// 获取分类数据
		getCategoryList() {
			this.loading = true;
			this.http
				.get('/platform/study/category')
				.then(ret => {
					if (ret.code == 200) {
						this.loading = false;
						this.categoryList = ret.data;
					}
				})
				.catch(err => {
					this.loading = false;
					console.log(err);
				});
		}
	}
};
</script>

<style></style>
